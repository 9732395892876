$(function(){
  // グロナビ メニューボタン
  $(".navbar .nav-item a").smoothScroll({
    offset: -75
  });
  $("a.to-form").smoothScroll({
    offset: -75
  });
  // $(".navbar-toggler").on("click", function() {
  //   $(".menu1").toggleClass("menuclick1");
  // });
  // $(".navbar-toggler").on("click", function() {
  //   $(".menu2").toggleClass("menuclick2");
  // });
  // $(".navbar-toggler").on("click", function() {
  //   $(".menu3").toggleClass("menuclick3");
  // });

  $(".covid a").smoothScroll({
    offset: -70
  });
  // スライダー
  $('.slider1').slick({
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    variableWidth: true
	});
  $('.slide2').slick({
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 2090,
    speed: 1010,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true
	});
  $('#faq dl dt').on('click', function() {
    $(this).next('dd').slideToggle(function(){
      $(this).parent().toggleClass('faq-close');
    });
    $(this).attr('onclick','');
  });
  $('#pagenav a').click(function(){
    var id = $(this).attr('href');
    var position = $(id).offset().top;
    $('html, body').animate({
      'scrollTop':position+'px'
    },500);
    return false;
  });


  // date
  var date = new Date();
  var copyrightYear = date.getFullYear();
  $('.copyright span').text(copyrightYear);
});



//toTopの表示・非表示
function displayToCovid(){
  var scrollTop = $(window).scrollTop();
  if (scrollTop > 100) {
    $(".covid").fadeOut();
  } else {
    $(".covid").fadeIn();
  }
}
$(window).on("load scroll", displayToCovid);
